import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { State } from '../../../auth/models/auth.types';
import { Words } from '../../interfaces/questions.types';

@Component({
  selector: 'app-que-oddoneout',
  templateUrl: './que-oddoneout.component.html',
  styleUrls: ['./que-oddoneout.component.scss']
})
export class QueOddOneOutComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() correct: number;
  @Input() question: any = null;
  @Output() answerEvent = new EventEmitter();

  subs = new Subscription();

  state: State;
  answers: Words[] = [];
  words_row1: Words[];
  words_row2: Words[];

  constructor(
    private readonly router: Router,
    private readonly authStateService: AuthStateService,
  ) {}

  ngOnInit() {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
    this.setup();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidEnter() {
    this.setup();
  }

  setup() {
    this.answers = [];
    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    this.words_row1 = this.question.words.slice(0, 3);
    this.words_row2 = this.question.words.slice(3);
  }

  toggleWord(word) {
    if (!this.isSelected(word)) {
      this.answers.push(word);
      console.log('Emit word:' + JSON.stringify(word));
    } else {
      const answers = this.answers.filter((w) => w.id !== word.id);
      this.answers = answers;
    }
    this.answerEvent.emit(this.answers);
  }

  wordClass(word: Words): string {
    if (this.correct !== null && word.points > 0) {
      return 'word-correct';
    }

    if (this.isSelected(word)) {
      return 'word-selected';
    }

    return '';
  }

  isSelected(word: Words) {
    const existingWord = this.answers.find((w) => w.id === word.id);
    if (existingWord != null) {
      return true;
    }
    return false;
  }

  exitGame() {
    if (this.state.user.first) {
      this.authStateService.logout();
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }

  exitImpersonatedParent() {
    if (this.state.user.first) {
      this.authStateService.signOutImpersonate(this.state);
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }
}
