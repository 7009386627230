<div class="center-all column sortt">
  <div class="center-all column">
    <p class="instruction">{{ question.question }}</p>
    <ion-grid class="word-list word-list-size content-bg-color">
      <ion-row>
        <ion-col size="3"></ion-col>
        <ion-col size="2" *ngFor="let word of words_row1" class="word-item" [class.filled]="answers[word.id]" [class.unfilled]="!answers[word.id]">
          <div (click)="toggleWord(word)">
            <div class="word-image word-image-size">
            </div>
            <div id="Row1_{{word.id}}" class="word word-size" [class]="wordClass(word)">
              {{word.word}}
            </div>
          </div>
        </ion-col>
        <ion-col size="3"></ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="3"></ion-col>
        <ion-col size="2" *ngFor="let word of words_row2" class="word-item" [class.filled]="answers[word.id]" [class.unfilled]="!answers[word.id]">
          <div (click)="toggleWord(word)">
            <div class="word-image word-image-size">
            </div>
            <div id="Row2_{{word.id}}" class="word word-size" [class]="wordClass(word)">
              {{word.word}}
            </div>
          </div>
        </ion-col>
        <ion-col size="3"></ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
