<ion-content>
  <div class="full-width">
    <ng-container *ngFor="let item of questions.set; let i = index">
      <div class="fit-to-div" *ngIf="i === queType">
        <app-que-roll *ngIf="type === 'roll'" [question]="question" (selectedEvent)="selectedEvent($event)"></app-que-roll>
        <app-que-arrows *ngIf="type === 'arrows'" [question]="question" [selectedTarget]="selectedTargetArrow" (arrowEvent)="arrowEvent($event)"></app-que-arrows>
        <app-que-brick-wall *ngIf="type === 'brick-wall'" [question]="question" (selectedEvent)="selectedEvent($event)" (pointsEvent)="pointsEvent($event)"></app-que-brick-wall>
        <app-que-drag *ngIf="type === 'drag'" [question]="question" [correct]="correct" [correctAnswerID]="correctAnswerID" (answersDragEvent)="answersDragEvent($event)" [hasCompleted]="hasCompleted"></app-que-drag>
        <app-que-select *ngIf="type === 'select'" [question]="question" [correctAnswerID]="correctAnswerID" [correct]="correct" (answerSelectEvent)="answerSelectEvent($event)"></app-que-select>
        <app-que-slide *ngIf="type === 'slide'" [question]="question" (selectedEvent)="selectedEvent($event)"></app-que-slide>
        <app-que-sort *ngIf="type === 'sort'" [question]="question" [correctOrder]="correctOrder" [correct]="correct" (answerEvent)="answerEvent($event)"></app-que-sort>
        <app-que-spin *ngIf="type === 'spin'" [question]="question" (selectedEvent)="selectedEvent($event)" (firstSpin)="firstSpin($event)"></app-que-spin>
        <app-que-anagram *ngIf="type === 'anagram'" [question]="question" (answerEvent)="answerEvent($event)" (processAnswerEvent)="nextQuestion()"></app-que-anagram>
        <app-que-oddoneout *ngIf="type === 'oddoneout'" [question]="question" (answerEvent)="answerEvent($event)" [correct]="correct" ></app-que-oddoneout>
        <app-que-cloze *ngIf="type === 'cloze'" spin [question]="question" [correct]="correct" [correctAnswerID]="correctAnswerID" (cloePuzzleEvent)="cloePuzzleEvent($event)"></app-que-cloze>
        <app-que-connections *ngIf="type === 'connection'" [question]="question" [isWrongAnswer]="isWrongAnswerConnection" (connectionEvent)="connectionEvent($event)" [hasCompleted]="hasCompleted"></app-que-connections>
      </div>
      <app-game-buttons [correct]="correct" [correctWord]="correctWord" [type]="type" (nextBtnClick)="nextQuestion()" (exitBtnClick)="exitImpersonatedParent()" [isVisible]="isGameButtonVisible"></app-game-buttons>
    </ng-container>
  </div>
</ion-content>
