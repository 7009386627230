import bugsnag from './bugsnag';
import firebase from './firebase';
import revenuecatProd from './revenuecat.prod';
import stripeLive from './stripe.live';

export const environment = {
  maintenance: false,
  production: true,
  title: 'ChatterStars',
  name: 'production',
  baseUrl: 'https://api-v2.chatterstars.co.uk/',
  appUrl: 'https://user.chatterstars.co.uk/',
  appId: '1',
  settings: {
    disableLoginChecks: false,
    enableMockData: false
  },
  hubspot: {
    enabled: true
  },
  stripe: stripeLive,
  firebase,
  bugsnag,
  revenuecat: revenuecatProd
};
