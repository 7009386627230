import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ModalOptions, NavController } from '@ionic/angular';
import { DragulaService } from 'ng2-dragula';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { State } from 'src/app/modules/auth/models/auth.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { PopUpPage } from 'src/app/modules/common/modals/pop-up/pop-up.page';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { Constants } from 'src/app/util/constants';
import { environment } from '../../../../../environments/environment';
import { Words } from '../../interfaces/questions.types';
import { QuestionsService } from '../../services/questions.service';
import { CompletePage } from '../complete/complete.page';

@Component({
  selector: 'app-game-page',
  templateUrl: './game-page.component.html',
  styleUrls: ['./game-page.component.scss']
})
export class GamePageComponent implements OnDestroy, OnInit {
  type = '';
  question: any = null;
  answers: any = [[], [], []];
  correct: number = null;
  original_selection: any = [];
  selections: any = [];
  last_piece_is_selection = false;
  correctAnswerID: string[];
  selected: any = null;
  first_spin = 0;
  correctWord = '';
  correctOrder: string[] = new Array(4);
  previous_answers: any = [[], [], [], []];
  subs = new Subscription();
  answersSelected: any = {};
  answersDrag: any = [[], [], []];
  targets: Words[];
  selectedTarget: number;
  selectedTargetArrow: number;
  points: number;
  isGameButtonVisible = true;
  queType = -1;
  correctAntonym: any;
  set = 0;
  completeModalOpen = false;
  brickPoints: any;
  clozeGamePoint = '';
  connectionPoint = '';
  isWrongAnswerConnection = false;
  hasCompleted: boolean;
  state: State;

  private numberOfAnswers = 3;

  constructor(
    readonly questions: QuestionsService,
    private readonly dragulaService: DragulaService,
    private readonly audio: AudioService,
    private readonly router: Router,
    private readonly modalCtrl: ModalController,
    private readonly route: ActivatedRoute,
    private readonly authStateService: AuthStateService,
    private readonly navController: NavController
  ) {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  async ngOnInit() {
    const type = this.router.url.split('/')[2];
    const set = this.router.url.split('/')[3];

    if (!isNaN(Number(set))) {
      this.questions.resetAnswers();
    }

    if (type === 'connection' || type === 'cloze') {
      this.type = type;
    } else {
      this.loadData();
    }
  }

  ngOnDestroy() {}

  async loadData() {
    // Ensure the 11+ app does not use the initial question set API call
    let firstSet = this.state.user.first ?? false;
    if (environment.appId === '2') {
      firstSet = false;
    }
    (await this.questions.getSet(firstSet)).subscribe(
      (result) => {
        this.questions.setQuestions(result.payload.questions);
        this.loadQuestion();
      },
      async (err) => {
        const errDlg = await this.modalCtrl.create({
          component: PopUpPage,
          showBackdrop: false,
          componentProps: {
            title: 'Error',
            text: err.error.errorMessage
          }
        });
        await errDlg.present();
      }
    );
  }

  async loadQuestion() {
    this.correct = null;
    this.correctWord = '';
    this.selected = null;
    this.isGameButtonVisible = true;
    this.queType++;
    this.type = this.questions.getNextQuestionType(this.queType);

    this.question = this.questions.getQuestion();

    if (this.type === 'complete') {
      const errDlg = await this.modalCtrl.create({
        component: CompletePage,
        showBackdrop: false,
        componentProps: {
          title: 'Complete'
        }
      });
      this.completeModalOpen = true;
      await errDlg.present();
    } else {
      this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');
    }

    if (this.type === 'arrows') {
      this.targets = this.question.words
        .filter((word, index) => index % 2 !== 0)
        .sort((a, b) => a.selection_number - b.selection_number);
      this.correctAntonym = this.question.words.find((word) => word.points === this.question.pass_mark);
    }

    if (this.type === 'anagram') {
      this.isGameButtonVisible = false;
    }

    if (this.type !== 'complete' && this.type !== 'cloze') {
      this.setup();
    }

    this.hasCompleted = false;
  }

  arrowEvent(selectedTarget) {
    this.selectedTarget = selectedTarget;
  }

  selectedEvent(selected) {
    if (selected) {
      this.selected = selected;
    } else {
      this.selected = null;
    }
  }

  pointsEvent(points) {
    if (points) {
      this.brickPoints = points;
    } else {
      this.brickPoints = 0;
    }
  }

  firstSpin(spin) {
    if (spin) {
      this.first_spin = spin;
    }
  }

  answerEvent(answers) {
    this.answers = answers;
  }

  answerSelectEvent(answers) {
    this.answersSelected = answers;
  }

  answersDragEvent(answers) {
    this.answersDrag = answers;
  }

  cloePuzzleEvent(point) {
    this.clozeGamePoint = point;
  }

  connectionEvent(answer) {
    this.connectionPoint = answer;
  }

  async nextQuestion() {
    this.hasCompleted = true;

    const timeInterval = Constants.delayTime.inCorrectAnswerDelay;
    if (this.type === 'roll') {
      if (this.selected != null && this.selected !== '' && this.selected !== undefined) {
        const response = await this.questions.answerQuestion(this.question.words[this.selected - 1].points);
        this.correctWord = '';

        response.correctWord.forEach((word, index) => (this.correctWord += response.correctWord[index] + ', '));

        this.correctWord = this.correctWord.slice(0, -2);
        this.correct = response.correct;
      } else {
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'slide') {
      let selected;
      if (!isNaN(Number(this.selected))) {
        selected = this.question.words[this.selected ? Number(this.selected) : 0];
      } else {
        return this.openSelectAnswerModal();
      }
      if (!selected || selected.points !== 'none') {
        const response = await this.questions.answerQuestion(selected.points);
        this.correctWord = response.correctWord[0]; // Load the correct word
        this.correct = response.correct; // Boolean correct/incorrect
      } else {
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'spin') {
      if (!isNaN(Number(this.selected))) {
        this.selected = Number(this.selected);
      }
      if (this.selected || this.selected === 0) {
        const points = this.question.words[Number(this.selected)].points;

        const response = await this.questions.answerQuestion(points);

        this.correctWord = response.correctWord[0]; //Load the correct word
        this.correct = response.correct; //Boolean correct/incorrect
      } else {
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'sort') {
      if (this.answers[0][0] && this.answers[1][0] && this.answers[2][0] && this.answers[3][0]) {
        const points = this.answers[0][0].points || this.answers[1][0].points;
        const response = await this.questions.answerQuestion(points);

        //Get correct order
        for (const answer of this.answers) {
          this.correctOrder[parseInt(answer[0].selection_number, 10) - 1] = answer[0].word;
        }

        this.correct = response.correct; //Boolean correct/incorrect
      } else {
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'select') {
      let answered = 0;
      let points = 0;
      for (const a in this.answersSelected) {
        if (this.answersSelected[a]) {
          for (const w of this.question.words) {
            if (a === w.id.toString()) {
              points += w.points;
              break;
            }
          }

          answered++;
        }
      }

      if (answered) {
        const response = await this.questions.answerQuestion(points);

        this.correctAnswerID = response.correctWordID;
        this.correct = response.correct;
      } else {
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'drag') {
      let answered = 0;
      let points = 0;
      for (const a of this.answersDrag) {
        if (a.length) {
          points += a[0].points;
          answered++;
        }
      }

      if (answered >= this.numberOfAnswers) {
        const response = await this.questions.answerQuestion(points);
        this.correctAnswerID = response.correctWordID;
        this.correct = response.correct;
      } else {
        return this.openSelectAnswerModal();
      }
    }

    if (this.type === 'brick-wall') {
      if (this.brickPoints || this.brickPoints === 0) {
        if (this.selected) {
          this.correct = 0;
          this.correctWord = this.selected;
        } else {
          this.correct = 1;
          this.brickPoints = this.question.pass_mark;
        }
        const response = await this.questions.answerQuestion(this.brickPoints);
      } else {
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'cloze') {
      this.correct = 0;
      if (this.clozeGamePoint && !isNaN(Number(this.clozeGamePoint))) {
        const point = Number(this.clozeGamePoint);
        const response = await this.questions.answerQuestion(point);
        if (point === 10) {
          this.correct = 1;
        } else {
          this.correct = 0;
          this.correctWord = response.correctWord[0];
        }
      } else {
        this.correct = null;
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'connection') {
      this.correct = 0;
      if (this.connectionPoint) {
        let point = 0;
        if (this.connectionPoint === 'correct') {
          point = this.question.pass_mark;
          this.correct = 1;
        } else {
          this.isWrongAnswerConnection = true;
        }
        const response = await this.questions.answerQuestion(point);
      } else {
        this.correct = null;
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'oddoneout') {
      if (
        this.answers.flat().length === 0 ||
        this.answers.length < this.question.words.filter((w) => w.points > 0).length
      ) {
        return this.openSelectAnswerModal();
      }

      this.correct = this.answers.reduce((acc, answer) => answer.points + acc, 0) === this.question.pass_mark ? 1 : 0;
    }
    if (this.type === 'anagram') {
      if (this.answers) {
        let points = 0;
        if (this.answers !== this.question.words[0].word) {
          this.correct = 0;
        } else {
          this.correct = 1;
          points = this.question.words[0].points;
        }
        const response = await this.questions.answerQuestion(points);
      } else {
        return this.openSelectAnswerModal();
      }
    }
    if (this.type === 'arrows') {
      this.points = 0;
      const arrowFlightTime = 300;
      const answerRevealTime = 2000;
      if (this.selectedTarget || this.selectedTarget === 0) {
        this.selectedTargetArrow = this.selectedTarget;
        if (this.targets[this.selectedTarget].points >= this.question.pass_mark) {
          this.points = this.points + this.targets[this.selectedTarget].points;

          this.correct = 1;
        } else {
          this.correct = 0;
          this.correctWord = this.correctAntonym.word;
        }
        const response = await this.questions.answerQuestion(this.points);
      } else {
        return this.openSelectAnswerModal();
      }
    }
    const timeInter = this.correct ? 1000 : 3000;
    if (this.correct) {
      this.audio.play('correct');
    } else {
      this.audio.play('incorrect');
    }

    await new Promise((resolve) => setTimeout(resolve, timeInter));

    this.loadQuestion();
  }

  async openSelectAnswerModal() {
    const modalConfig: ModalOptions = {
      component: PopUpPage,
      showBackdrop: false,
      componentProps: {
        title: 'Answer',
        text: 'Please select all the required answers'
      },
      cssClass: 'pop-up'
    };

    this.modalCtrl.create(modalConfig).then((modal) => modal.present());
  }

  setup() {
    this.answers = [[], [], []];
    this.correct = null;
    // this.question = Object.assign({}, this.questions.getQuestion());
    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    for (let i = 1; i < 10; i++) {
      const selection = '[selection' + i + ']';

      if (this.question.construct.indexOf(selection) === -1) {
        break;
      }

      this.question.construct = this.question.construct.replace(selection, '<BREAK>');
    }
  }

  exitGame() {
    // Ensure lines are not shown
    this.clearLines();
    if (this.state.user.first) {
      this.authStateService.logout();
    } else {
      this.navController.navigateBack(['child/dashboard']);
    }
  }

  exitImpersonatedParent() {
    // Ensure lines are not shown
    this.clearLines();
    this.navController.navigateBack(['child/dashboard']);
  }

  clearLines() {
    const lines = document.getElementsByClassName('leader-line');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    const count = lines.length;
    for (let index = count - 1; index >= 0; index--) {
      const currentLine = lines[index] as any;
      currentLine.remove();
    }
  }
}
